LemonFrog.Payments = LemonFrog.Payments || {};

LemonFrog.Payments.StripeCardsForm = (function () {
  const moduleName = 'Payments.StripeCardsForm',
    moduleSel = '[data-module="' + moduleName + '"]',
    cardErrorsSel = '#card-errors',
    paymentFormAlertSel = '.js-alert',
    paymentFormChargeDetailsBoxSel = '.js-stripe-form-charge-details-box',
    paymentFormLabelSel = '.js-stripe-form-label',
    paymentFormSubmitSel = '.js-stripe-form-submit';

  const cardInputOptions = {
    classes: {
      base: 'new-styling stripe-form__input',
      complete: 'stripe-form__input--complete',
      empty: 'stripe-form__input--empty',
      focus: 'stripe-form__input--focus',
      invalid: 'stripe-form__input--invalid'
    },
    placeholder: '',
    style: {
      base: {
        color: '#4A4A4A',
        fontSize: '16px',
        fontWeight: '400'
      }
    }
  };

  let cardNumberElement = null,
    inputs = {
      cardCvc: { completed: false, error: null },
      cardExpiry: { completed: false, error: null },
      cardNumber: { completed: false, error: null }
    };

  function init() {
    initStripeElements();
    initFormEventsHandlers();
    showLabels();
    initPaymentActions();
  }

  function initStripeElements() {
    cardNumberElement =
      elements.create('cardNumber', { ...cardInputOptions, showIcon: true });
    cardNumberElement.mount('#card-number-element');
    const cardExpiryElement = elements.create('cardExpiry', cardInputOptions);
    cardExpiryElement.mount('#card-expiry-element');
    const cardCvcElement = elements.create('cardCvc', cardInputOptions);
    cardCvcElement.mount('#card-cvc-element');

    handleInputsChangeEvents(cardNumberElement);
    handleInputsChangeEvents(cardExpiryElement);
    handleInputsChangeEvents(cardCvcElement);
  }

  function handleInputsChangeEvents(element) {
    element.on('change', function(event) {
      inputs[event.elementType]['error'] = event.error && event.error.message;
      let errors =
        Object.values(inputs).map(el => el['error']).filter(el => el).join(' ');
      handleErrorMessages(errors);
    });
  }

  function handleErrorMessages(errors) {
    if (errors) {
      $(moduleSel).find(cardErrorsSel).text(errors);
      $(moduleSel).find(cardErrorsSel).show();
    } else {
      $(moduleSel).find(cardErrorsSel).hide();
    }
  };

  function initFormEventsHandlers() {
    // Using jQuery .on here leads to trigger form's onSubmit event
    // so infinity loop happens - with VanillaJS event handler it works correctly
    $(moduleSel)[0].addEventListener('submit', submitForm);
  }

  function initPaymentActions() {
    const $requiredActionInput = $(moduleSel).children('#required_action'),
      $clientSecretInput = $(moduleSel).children('#client_secret');

    if ($requiredActionInput.length) {
      switch ($requiredActionInput.val()) {
        case 'SetupIntent':
          handleRequiredSetupIntentAction($clientSecretInput.val());
          break;
        case 'PaymentIntent':
          handleRequiredPaymentIntentAction($clientSecretInput.val());
          break;
        case 'CardPayment':
          handleRequiredCardPaymentAction($clientSecretInput.val());
          break;
      }
    }
  }

  function showLabels() {
    $(moduleSel).find(paymentFormLabelSel).show();
  }

  function stripePaymentMethodHandler(token) {
    // Insert the paymentMethod ID into the form so it gets submitted to the server
    let $hiddenInput = $('<input>', {
      type: 'hidden',
      name: 'stripeToken',
      value: token
    });
    $(moduleSel).append($hiddenInput);
    $(moduleSel).submit();
  }

  function handleRequiredCardPaymentAction(cardPaymentClientSecret) {
    $(moduleSel).find(paymentFormSubmitSel).prop('disabled', true);

    stripe.handleCardPayment(cardPaymentClientSecret).then(function(result) {
      if (result.error) {
        handleStripeError(result.error.message);
      } else {
        stripePaymentMethodHandler(result.paymentIntent.id);
      }
    });
  }

  function handleRequiredPaymentIntentAction(paymentIntentClientSecret) {
    $(moduleSel).find(paymentFormSubmitSel).prop('disabled', true);

    stripe.handleCardAction(paymentIntentClientSecret).then(function(result) {
      if (result.error) {
        handleStripeError(result.error.message);
      } else {
        stripePaymentMethodHandler(result.paymentIntent.id);
      }
    });
  }

  function handleRequiredSetupIntentAction(setupIntentClientSecret) {
    $(moduleSel).find(paymentFormSubmitSel).prop('disabled', true);

    stripe.handleCardSetup(setupIntentClientSecret).then(function(result) {
      if (result.error) {
        handleStripeError(result.error.message);
      } else {
        stripePaymentMethodHandler(result.setupIntent.payment_method);
      }
    });
  }

  function submitForm(event) {
    event.preventDefault();
    $(moduleSel).find(paymentFormSubmitSel).prop('disabled', true);

    stripe.createPaymentMethod('card', cardNumberElement).then(function (result) {
      if (result.error) {
        handleErrorMessages(result.error.message);
        $(moduleSel).find(paymentFormSubmitSel).prop('disabled', false);
      } else {
        handleErrorMessages(result.error);
        stripePaymentMethodHandler(result.paymentMethod.id);
      }
    });
  }

  function handleStripeError(error) {
    $(moduleSel).find(paymentFormSubmitSel).prop('disabled', false);
    $(moduleSel).children('#required_action').remove();
    $(moduleSel).children('#client_secret').remove();
    $(moduleSel).find(paymentFormAlertSel).find('.new-styling.notifications-component__text').text(error);
    $(moduleSel).find(paymentFormAlertSel).removeClass('hidden');
  }

  return {
    moduleName: moduleName,
    init: init,
  }
}());

LemonFrog.initModule(LemonFrog.Payments.StripeCardsForm);
