LemonFrog.SelectBox = (function() {
  const moduleName = 'SelectBox',
        moduleSel  = `[data-module="${moduleName}"]`;

  function init() {
    $(moduleSel).each(function () {
      updatePlaceholderState(this);
    })

    $(moduleSel).on('change', function() {
      updatePlaceholderState(this);
    })
  }

  function updatePlaceholderState(elem) {
    let value = $(elem).find('option:selected').val();

    if (value == "") {
      $(elem).addClass('placeholder');
    } else {
      $(elem).removeClass('placeholder');
    }
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.SelectBox);
