import { requireAll } from '/helpers/require';

requireAll(require.context('./jquery/other', true));

$(function () {
  let $landingPageHeaderContainer = $('.landing_page__header-container');

  if ($landingPageHeaderContainer.length) {
    mainImage();
    $(window).resize(function () {
      mainImage();
    });
  }

  function mainImage () {
    if ($(window).width() <= 767) {
      $landingPageHeaderContainer.css('background-image', 'url(' + mainMobileImageURL + ')');
    } else {
      $landingPageHeaderContainer.css('background-image', 'url(' + mainImageURL + ')');
    }
  };
});
