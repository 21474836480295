LemonFrog.Employees = (function () {
  const moduleName = 'Employees';

  function init() {
    $('.js-employees-count').html(
      $(`[data-module="${moduleName}"]`).children().length
    );
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.Employees);
